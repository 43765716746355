<template>
    <div :key="'table-'+reloadTable" class="card" :class="cardClass" :style="cardStyle" v-if="pageLoaded">

        <!-- HEADER -->

        <div class="card-header" :class="headerClass" :style="headerStyle"
             v-on:click="collapseTable"
             v-if="showHeader">
            <template v-if="collapsible">
                <div class="ml-2 mr-2" style="display: inline-block; width: 15px; text-align: center;">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm" v-if="isCollapsed"/>
                    <font-awesome-icon :icon="['fas', 'chevron-down']" size="sm" v-else/>
                </div>
            </template>
            <template v-if="!state.readonly">
                <div class="ml-1" style="display: inline-block;">
                    <template v-for="(action,actionIndex) in crudActions">
                        <ButtonAction :key="action.name+'-'+actionIndex"
                                      :action="action" :disabled="state.disabled" forward-events
                                      v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                      v-if="action.type === 'BUTTON' && action.placement === 'TABLEHEADERLEFT'"/>
                    </template>
                </div>
            </template>
            <template v-if="titleIcon">
                <div class="ml-1" style="display: inline-block; width: 15px; text-align: center;">
                    <font-awesome-icon :icon="titleIcon" size="sm"/>
                </div>
            </template>
            <template v-if="title">
                <div class="ml-1" style="display: inline-block;">
                    {{ title }}
                </div>
            </template>
            <template v-if="!state.readonly">
                <div class="float-right" style="display: inline-block;">
                    <template v-for="(action,actionIndex) in crudActions">
                        <ButtonAction :key="action.name+'-'+actionIndex"
                                      :action="action" :disabled="state.disabled" forward-events
                                      v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                      v-if="action.type === 'BUTTON' && action.placement === 'TABLEHEADERRIGHT'"/>
                    </template>
                </div>
            </template>
        </div>

        <!-- BODY -->

        <div class="card-body" :class="bodyClass" style="padding: 1rem;" :style="bodyStyle" v-if="!isCollapsed">

            <!-- FILTER -->

            <template v-if="withFilter">
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <font-awesome-icon :icon="['fas', 'search']" size="sm"/>
                        </span>
                    </div>
                    <div class="input-group-append" style="flex-grow: 1;">
                        <b-form-input type="search" :placeholder="$i18n.tc('translations.Filter',1)" :disabled="state.disabled" :autofocus="autofocus"
                                      v-model="filter"
                                      v-on:input="debounceInput"/>
                    </div>
                    <template v-for="(action,actionIndex) in crudActions">
                        <template v-if="action.type === 'BUTTON' && action.placement === 'FILTERRIGHT'">
                            <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                <ButtonAction :action="action" :disabled="state.disabled" forward-events
                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"/>
                            </div>
                        </template>
                        <template v-if="action.type === 'DROPDOWN' && action.placement === 'FILTERRIGHT'">
                            <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                <DropDownAction :action="action" :disabled="state.disabled" forward-events
                                                v-on:forward-event="handleForwardedActionEvent(action, $event)"/>
                            </div>
                        </template>
                        <template v-if="action.type === 'SWITCH' && action.placement === 'FILTERRIGHT'">
                            <div :key="action.name+'-'+actionIndex" class="input-group-append">
                                <span class="input-group-text">
                                    <SwitchAction :action="action" :state="state" forward-events
                                                  v-on:forward-event="handleForwardedActionEvent(action, $event)"/>
                                </span>
                            </div>
                        </template>
                    </template>
                </div>
            </template>

            <table class="table table-hover table-bordered table-striped table-sm m-0 table-highlight">

                <!-- TABLE HEADER -->

                <thead>
                <tr>
                    <template v-for="(field,index) in crudFields">
                        <template v-if="field.showintable || field.editintable">
                            <th :key="'tableheader-' + field.name + '-' + index" :class="field.thclass" :style="field.thstyle"
                                v-if="field.thicon">
                                <font-awesome-icon :icon="field.thicon" size="sm"/>
                            </th>
                            <th :key="'tableheader-' + field.name + '-' + index" :class="field.thclass" :style="field.thstyle"
                                v-else-if="field.type === 'ID'">
                                <input type="checkbox" title="select" v-model="selectAll"
                                       v-on:change="toggleAll">
                            </th>
                            <th :key="'tableheader-' + field.name + '-' + index" :class="field.thclass" :style="field.thstyle"
                                v-else>
                                {{ field.label }}
                            </th>
                        </template>
                    </template>
                    <template v-if="showActions && !state.readonly">
                        <th v-if="noActionsLabel" :class="thActionsClass" :style="thActionsStyle"/>
                        <th v-else class="text-center" style="width: 135px;" :class="thActionsClass" :style="thActionsStyle">
                            <font-awesome-icon :icon="['fas', 'cogs']" size="sm"/>
                            {{ $i18n.t('translations.Actions') }}
                        </th>
                    </template>
                </tr>
                </thead>

                <!-- TABLE BODY -->

                <tbody :key="'table-body-'+reloadTableData">
                <template v-for="(row,rowIndex) in tableRows">
                    <tr :key="'tablerow-' + row.id + '-' + rowIndex + '-' + reloadRow" :class="{'selected-row': (row.id === selectedRow)}">
                        <template v-for="(field,fieldIndex) in crudFields">
                            <template v-if="field.showintable || field.editintable">
                                <td :key="'td-' + rowIndex + '-' + fieldIndex"
                                    :class="field.tdclassfield ? row[field.tdclassfield] : field.tdclass"
                                    :style="field.tdstylefield ? row[field.tdstylefield] : field.tdstyle"
                                    :title="fieldTitle(field, row)">
                                    <Field :field="field" :data="row" :state="state"
                                           v-on:forward-event="handleForwardedFieldEvent(field, $event)"
                                           v-on:inline-edit="inlineEdit(rowIndex,$event)"
                                           v-on:toggle-row="toggleRow"/>
                                </td>
                            </template>
                        </template>
                        <template v-if="showActions && !state.readonly">
                            <td style="text-align: center;">
                                <template v-for="(action,actionIndex) in crudActions">
                                    <template v-if="!action.hidefield || !row[action.hidefield]">
                                        <ButtonAction :key="action.name + '-' + rowIndex + '-' + actionIndex"
                                                      :action="action" :disabled="state.disabled" forward-events
                                                      v-on:forward-event="handleForwardedActionEventForRow(action, $event, row)"
                                                      v-if="action.type === 'BUTTON' && action.placement === 'TABLEROW'"/>
                                        <RouteAction :key="action.name + '-' + rowIndex + '-' + actionIndex" :action="action" :disabled="state.disabled" :data="row"
                                                     v-if="action.type === 'ROUTE' && action.placement === 'TABLEROW'"/>
                                    </template>
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>

                <!-- TABLE TOTALS -->

                <template v-if="totalFields">
                    <tr>
                        <td class="st-total" :colspan="colSpan">
                            {{ $i18n.t('translations.Total') }}
                        </td>
                        <template v-for="(field,index) in totalFields">
                            <td :key="'td-totals-' + index"
                                :class="field.tdclassfield ? totals[field.tdclassfield] : field.tdclass"
                                :style="field.tdstylefield ? totals[field.tdstylefield] : field.tdstyle"
                                :title="fieldTitle(field, totals)">
                                <Field :field="field" :data="totals" :state="state"/>
                            </td>
                        </template>
                        <template v-if="showActions && !state.readonly">
                            <td/>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>

            <!-- PAGINATION -->

            <template v-if="meta && meta.last_page > 1">
                <ul class="pagination justify-content-center mt-3 mb-0">
                    <li class="page-item" :class="{'disabled' : meta.current_page === 1}">
                        <button class="page-link" v-on:click="firstPage">
                            <font-awesome-icon :icon="['fas', 'angle-double-left']" size="sm"/>
                        </button>
                    </li>
                    <li class="page-item" :class="{'disabled' : meta.current_page === 1}">
                        <button class="page-link" v-on:click="previousPage">
                            <font-awesome-icon :icon="['fas', 'angle-left']" size="sm"/>
                        </button>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link">
                            {{ meta.current_page }} {{ $i18n.t('translations.of') }} {{ meta.last_page }}
                            ({{ meta.total }}{{ numberOfSelectedRows > 0 ? ', ' + $i18n.tc('translations.selected', numberOfSelectedRows) : '' }})
                        </a>
                    </li>
                    <li class="page-item" :class="{'disabled' : meta.current_page === meta.last_page}">
                        <button class="page-link" v-on:click="nextPage">
                            <font-awesome-icon :icon="['fas', 'angle-right']" size="sm"/>
                        </button>
                    </li>
                    <li class="page-item" :class="{'disabled' : meta.current_page === meta.last_page}">
                        <button class="page-link" v-on:click="lastPage">
                            <font-awesome-icon :icon="['fas', 'angle-double-right']" size="sm"/>
                        </button>
                    </li>
                </ul>
            </template>

        </div>

        <!-- FOOTER -->

        <template v-if="!state.readonly">
            <div class="card-footer" :class="footerClass" :style="footerStyle" v-if="!isCollapsed && showFooter">
                <div class="row">
                    <div class="col form-inline">
                        <template v-for="(action,actionIndex) in crudActions">
                            <template v-if="action.placement === 'TABLEFOOTERLEFT' && (action.requires === 'sketchup' ? sketchUpBrowser : true)">
                                <ButtonAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                              :action="action" :disabled="actionDisabled(action)" forward-events
                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                              v-if="action.type === 'BUTTON'"/>
                                <DropDownAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                                :action="action" :disabled="actionDisabled(action)" forward-events
                                                v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                v-if="action.type === 'DROPDOWN'"/>
                            </template>
                        </template>
                    </div>
                    <div class="col-auto form-inline">
                        <template v-for="(action,actionIndex) in crudActions">
                            <template v-if="action.placement === 'TABLEFOOTERRIGHT' && (action.requires === 'sketchup' ? sketchUpBrowser : true)">
                                <ButtonAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                              :action="action" :disabled="actionDisabled(action)" forward-events
                                              v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                              v-if="action.type === 'BUTTON'"/>
                                <DropDownAction :key="'crudfooter-'+action.name+'-'+actionIndex"
                                                :action="action" :disabled="actionDisabled(action)" forward-events
                                                v-on:forward-event="handleForwardedActionEvent(action, $event)"
                                                v-if="action.type === 'DROPDOWN'"/>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import * as _ from "debounce";
import ButtonAction from "@/components/actions/ButtonAction";
import DropDownAction from "@/components/actions/DropDownAction";
import Field from "@/components/fields/Field";
import FieldTitle from "@/mixins/FieldTitle";
import RouteAction from "@/components/actions/RouteAction";
import SwitchAction from "@/components/actions/SwitchAction";

/* global sketchup:false */

export default {
    name: "Table",
    components: {
        ButtonAction,
        DropDownAction,
        Field,
        RouteAction,
        SwitchAction,
    },
    props: {
        actions: {type: Array, default: null},
        api: {type: String, default: null},
        autofocus: {type: Boolean, default: false},
        bodyClass: {type: String, default: 'st-fadein-content'},
        bodyStyle: {type: String, default: null},
        cardClass: {type: String, default: null},
        cardStyle: {type: String, default: null},
        collapsed: {type: Boolean, default: false},
        collapsible: {type: Boolean, default: false},
        fields: {type: Array, default: null},
        footerClass: {type: String, default: null},
        footerStyle: {type: String, default: null},
        forwardEvents: {type: Boolean, default: false},
        headerClass: {type: String, default: null},
        headerStyle: {type: String, default: null},
        noActionsLabel: {type: Boolean, default: false},
        noStateLoading: {type: Boolean, default: false},
        linesPerPage: {type: Number, default: 20},
        reloadTable: {type: Number, default: 0},
        reloadTableData: {type: Number, default: 0},
        rows: {type: Array, default: null},
        selection: {type: Boolean, default: false},
        state: {type: Object, default: () => ({})},
        thActionsClass: {type: String, default: null},
        thActionsStyle: {type: String, default: null},
        titleIcon: {type: Array, default: null},
        title: {type: String, default: null},
        totals: {type: Object, default: null},
        totalFields: {type: Array, default: null},
        withFilter: {type: Boolean, default: false},
        withPagination: {type: Boolean, default: false},
    },
    mixins: [FieldTitle],
    data() {
        return {
            pageLoaded: false,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            isCollapsed: !!this.collapsed,
            reloadRow: 0,
            selectedRow: null,
            filter: '',
            current_page_link: this.api,
            crudActions: this.actions ? this.actions : [],
            crudFields: this.fields ? this.fields : [],
            filteredRows: this.rows,
            tableRows: null,
            links: [],
            meta: null,
            numberOfSelectedRows: 0,
            selectAll: false,
            showHeader: false,
            showFooter: false,
            showActions: false,
        }
    },
    watch: {
        reloadTableData: function () {
            this.filterRows();
            if (this.withPagination) {
                this.setPage(this.meta.current_page);
            } else {
                this.tableRows = this.filteredRows;
            }
        }
    },
    computed: {
        colSpan: function () {
            let tableFields = 0;
            this.fields.forEach(field => {
                if (field.showintable || field.editintable) {
                    tableFields++;
                }
            });
            return tableFields - this.totalFields.length;
        },
    },
    created() {
        this.setStateLoading(true);
        if (this.api) {
            this.crud();
        } else if (this.withPagination) {
            this.setPage(1);
            this.checkLayout();
            this.pageLoaded = true;
            this.setStateLoading(false);
        } else {
            this.checkLayout();
            this.tableRows = this.rows;
            this.pageLoaded = true;
            this.setStateLoading(false);
        }
    },
    methods: {
        actionDisabled(action) {
            if (this.state.disabled) {
                return true;
            }
            if (action.enable === 'selection') {
                return !(this.selection || this.numberOfSelectedRows > 0);
            }
            return false;
        },
        buttonAction(action, data) {
            if (action.enable === 'selection') {
                let ids = [];
                if (this.api) {
                    this.tableRows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                } else {
                    this.rows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                }
                data.ids = ids;
            }
            this.$emit(action.name, data);
        },
        countSelectedRows() {
            this.numberOfSelectedRows = 0;
            if (this.api) {
                this.tableRows.forEach(row => {
                    if (row.selected) {
                        this.numberOfSelectedRows++;
                    }
                });
            } else {
                this.rows.forEach(row => {
                    if (row.selected) {
                        this.numberOfSelectedRows++;
                    }
                });
            }
        },
        checkLayout() {
            let show = !!this.title;
            if (!show) {
                this.crudActions.forEach(action => {
                    if (action.placement === 'TABLEHEADERLEFT' || action.placement === 'TABLEHEADERRIGHT') {
                        show = true;
                    }
                });
            }
            this.showHeader = show;

            show = false;
            this.crudActions.forEach(action => {
                if (action.placement === 'TABLEFOOTERLEFT' || action.placement === 'TABLEFOOTERRIGHT') {
                    show = true;
                }
            });
            this.showFooter = show;

            show = false;
            this.crudActions.forEach(action => {
                if (action.placement === 'TABLEROW') {
                    show = true;
                }
            });
            this.showActions = show;
        },
        collapseTable() {
            if (this.collapsible) {
                this.isCollapsed = !this.isCollapsed;
                this.$emit('collapse-table', this.isCollapsed);
            }
        },
        changePage(link) {
            this.setStateLoading(true);
            this.current_page_link = link;
            this.crud();
        },
        crud() {
            this.$http.post(this.current_page_link, {filter: this.filter}, {}).then((res) => {
                this.crudFields = res.data.fields;
                this.crudActions = res.data.actions ? res.data.actions : [];
                let tmp = res.data.data;
                if (tmp) {
                    tmp.forEach(row => {
                        row.selected = false;
                    })
                }
                this.tableRows = tmp;
                this.numberOfSelectedRows = 0;
                if (this.tableRows) {
                    this.links = res.data.links;
                    this.meta = res.data.meta;
                    this.current_page_link = this.meta.path;
                }

                this.checkLayout();

                this.pageLoaded = true;
                this.setStateLoading(false);
            }).catch((error) => {
                console.log("Table:crud():error:", error);
            });
        },
        debounceInput: _.debounce(function () {
            if (this.api) {
                this.crud();
            } else {
                this.filterRows();
                if (this.withPagination) {
                    this.setPage(1);
                } else {
                    this.tableRows = this.filteredRows;
                }
            }
        }, 450),
        dropDownSelectAction(action, choice) {
            let eventName = ('event' in choice) ? choice.event : action.name;
            if (action.enable === 'selection') {
                let ids = [];
                if (this.api) {
                    this.tableRows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                } else {
                    this.rows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                }
                choice.ids = ids;
            }
            this.$emit(eventName, choice);
        },
        filterRows() {
            if (this.filter) {
                this.filteredRows = [];
                this.rows.forEach(row => {
                    let found = false
                    this.fields.forEach(field => {
                        if ((field.showintable || field.editintable)) {
                            if (String(row[field.name]).toLowerCase().indexOf(this.filter.toLowerCase()) !== -1) {
                                found = true;
                            }
                        }
                    });
                    if (found) {
                        this.filteredRows.push(row);
                    }
                });
            } else {
                this.filteredRows = this.rows;
            }
        },
        firstPage() {
            if (this.api) {
                this.changePage(this.links.first);
            } else {
                this.setPage(1);
            }
        },
        handleForwardedActionEvent(action, eventData) {
            if (action.resethighlightrow) {
                this.selectedRow = null;
            }
            if (action.enable === 'selection') {
                let ids = [];
                if (this.api) {
                    this.tableRows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                } else {
                    this.rows.forEach(row => {
                        if (row.selected) {
                            ids.push(row.id);
                        }
                    });
                }
                eventData.data.ids = ids;
            }
            if (this.forwardEvents) {
                this.$emit('forward-event', eventData);
            } else {
                this.$emit(eventData.event, eventData.data);
            }
        },
        handleForwardedActionEventForRow(action, eventData, row) {
            if (action.highlightrow) {
                this.selectedRow = row.id;
            }
            eventData.data = row;
            this.handleForwardedActionEvent(action, eventData);
        },
        handleForwardedFieldEvent(field, eventData) {
            if (this.forwardEvents) {
                this.$emit('forward-event', eventData);
            } else {
                this.$emit(eventData.event, eventData.data);
            }
        },
        inlineEdit(index, data) {
            if (this.api) {
                this.tableRows[index] = data.data;
                this.reloadRow++;
                this.setStateLoading(false);
            } else {
                this.$emit('inline-edit', data);
            }
        },
        lastPage() {
            if (this.api) {
                this.changePage(this.links.last);
            } else {
                this.setPage(this.meta.last_page);
            }
        },
        nextPage() {
            if (this.api) {
                this.changePage(this.links.next);
            } else {
                this.setPage(this.meta.current_page + 1);
            }
        },
        previousPage() {
            if (this.api) {
                this.changePage(this.links.prev);
            } else {
                this.setPage(this.meta.current_page - 1);
            }
        },
        setPage(page) {
            this.setStateLoading(true);
            let last_page = Math.ceil(this.filteredRows.length / this.linesPerPage);
            this.meta = {
                total: this.filteredRows.length,
                last_page: last_page,
                per_page: this.linesPerPage,
                current_page: Math.min(Math.max(1, page), last_page),
            }
            this.meta.from = (this.meta.current_page - 1) * this.meta.per_page + 1;
            this.meta.to = Math.min((this.meta.from + this.meta.per_page - 1), this.filteredRows.length);
            this.tableRows = this.filteredRows.slice(this.meta.from - 1, this.meta.to);
            this.setStateLoading(false);
        },
        setStateLoading(value) {
            if (!this.noStateLoading) {
                this.state.loading = value;
            }
        },
        toggleAll() {
            if (this.api) {
                this.tableRows.forEach(row => {
                    row.selected = this.selectAll;
                })
            } else {
                this.rows.forEach(row => {
                    row.selected = this.selectAll;
                })
            }
            this.countSelectedRows();
            this.$emit('toggle-row', this.numberOfSelectedRows);
        },
        toggleRow() {
            this.countSelectedRows();
            this.$emit('toggle-row', this.numberOfSelectedRows);
        },
    }
}
</script>

<style scoped>
.table-highlight > tbody > tr.selected-row {
    outline: 1px solid darkslategray;
    background: lightgreen;
}
</style>
